<template>
  <div @click="updateState({ data:false,key:'showSearchTip'})">
    <layout-header />
    <div>
      <router-view  />
    </div>
    <layout-footer />
    <login />
  </div>
</template>

<script>
import LayoutFooter from "./component/layout-footer.vue";
import LayoutHeader from "./component/layout-header.vue";
import Login from "@/components/Login";
import loadBMap from "@/utils/loadBMap.js";
import Constant from "@/common/Constant";
import tool from "@/utils/tool.js";
import { mapState } from "vuex";
import houseServer from "@/services/houseServer"
import { setItem } from '@/utils/storage'


export default {
  name: "LayoutIndex",
  data() {
    return {
      isRefresh:false
    };
  },
  components: {
    LayoutFooter,
    LayoutHeader,
    Login
  },
  computed: {
    ...mapState(["isAllowPosition","companyConfig",'isGetLocation','cityCompany','location','isRefreshPage'])
  },
  methods: {
    updateState({data,key}) {
      this.$store.commit("updateState", {
        data,
        key
      });
    },
    async loadCompanyConfig() {
      const [err, { data:{logo,prefixName,suffixName,webSiteInfo,citys,tel} }] = await tool.to(houseServer.getCompanyConfig())
      if (err) {
        console.log('getCompanyConfig :>>',err);
        return
      }
      const _webSiteInfo = JSON.parse(webSiteInfo) || {}
      const logos = JSON.parse(logo) || {};
      if(citys) {
        const city = JSON.parse(citys)[0]
        const location = {
            cityCode: city.cityCode,
            cityName: city.city,
            latitude: city.lat,
            longitude: city.lng
        }
        this.$store.commit('saveLocation', location)
        this.updateState({data:location,key:"location"})
      }

      const newWebSiteInfo = {
        ...this.companyConfig.webSiteInfo,
        ..._webSiteInfo
      }
      const companyConfig = {
        ...this.companyConfig,
        ...logos,
        tel,
        firstName:prefixName,
        lastName:suffixName,
        webSiteInfo:newWebSiteInfo,
      }
      this.updateState({data:companyConfig,key:"companyConfig"})
      this.updateLinkIcon()
    },
    updateLinkIcon(icon = this.companyConfig.greenLogo) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link")
      link.rel = "icon"
      link.href = icon
      document.querySelector('head').appendChild(link)
    },
    async setCompanyByCityCode(cityCode = this.location.cityCode) {
      const [err,{data}] = await tool.to(houseServer.getCompanyByCityCode(cityCode))
      if(err) {
        console.log('getCompanyByCityCode :>>',err);
        return
      }
      this.$store.commit("updateState", { data: data || {}, key: 'cityCompany' });
      setItem(Constant.CITY_COMPANY,data)
      this.onRefreshPage()
    },
    onRefreshPage() {
      if(!this.isRefreshPage) {
        this.$router.go(0)
        setItem(Constant.IS_REFRESH_PAGE, true)
      }
    },
    loadCurrentLocation() {
      if(Constant.DEFAULT_COMPANY_GUID) {
         this.setCompanyByCityCode()
         return
      }
      setTimeout(() => {
          !this.isGetLocation && tool.getLocation(() => {
            !Object.keys(this.cityCompany).length && this.setCompanyByCityCode()
          })
        }, 500);
      }
  },
  created() {
    loadBMap(Constant.BAIDU_AK);
    this.loadCompanyConfig()
  },
  mounted() {
    this.loadCurrentLocation()
  }
};
</script>

<style scoped lang="less">
</style>
