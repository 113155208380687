
<template>
  <div class="footer-wrapper content">
    <div class="row sb_center">
      <!-- <ul class="row">
                <li v-for="nav in navs" class="nav-item bold size16" :key="nav.title">{{nav.title}}</li>
            </ul> -->
      <a @click="isShowConsult = true" href="javascript:;" class="size16 green bold">官方客服咨询</a>
    </div>
    <!-- <ul class="row house-wrapper ">
            <li v-for="house in houseList"
                @mouseover="mouseover(house)"
                class="size14 cursor transition"
                :class="[house.choose ? 'black':'no-choose']"
                :key="house.title"
            >{{house.title}}</li>
        </ul>
        <ul class="children-house-wrapper row wrap">
            <li v-for="children in filterHouse" class="colb7" :key="children.id">{{children.value}}</li>
        </ul> -->
    <div class="address colb7">
      <p>{{webSiteInfo.name}} I 地址: {{webSiteInfo.address}} </p>
      <a href="https://beian.miit.gov.cn" class="flex s_center">
        {{webSiteInfo.filing}} | © Copyright©2010-2022 {{webSiteInfo.webName}}</a>
    </div>

    <div v-if="isShowConsult" class="modal transition">
      <div class="modal-inner">
        <div class="close-modal cursor" @click="isShowConsult = false">
          <i class="el-icon-close size20"></i>
        </div>
        <h1 class="size24 black bold" style="margin-top:36px ">官方客服</h1>
        <p class="size12 colb7">您可以拨打客服热线获得帮助</p>
        <div class="row sb_center" style="margin-top:15px">
          <div class="fl">
            <div class="title-top">
              <span class="size16 bold">在线咨询</span>
              <!-- <span class="req-btn-span" @click="isShowConsult = false">立即咨询</span> -->
            </div>
            <p class="size12 colb7">09:00-20:00</p>
          </div>
          <div class="fl">
            <div class="title-top">
              <span class="size16 bold">客服热线</span>
              <span class="ml5 bold">{{companyConfig.tel || '028 6463 6764'}}</span>
            </div>
            <p class="size12 colb7">09:00-20:00</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      navs: [
        {
          title: "开放平台",
          page: ""
        },
        {
          title: "网站地图",
          page: ""
        },
        {
          title: "",
          page: ""
        },
        {
          title: "开放平台",
          page: ""
        }
      ],
      houseList: [
        {
          title: "二手房",
          choose: true,
          children: [
            {
              id: "01",
              value: "乐山房源"
            },
            {
              id: "01",
              value: "乐山房源"
            }
          ]
        },
        {
          title: "热门小区",
          choose: false,
          children: [
            {
              id: "01",
              value: "乐山房源aa"
            },
            {
              id: "01",
              value: "乐山房xx源"
            }
          ]
        },
        {
          title: "热门新房",
          choose: false,
          children: [
            {
              id: "01",
              value: "乐山房源"
            },
            {
              id: "01",
              value: "乐山房源"
            }
          ]
        },
        {
          title: "热门租房",
          choose: false,
          children: [
            {
              id: "01",
              value: "乐山房源"
            },
            {
              id: "01",
              value: "乐山房源"
            }
          ]
        }
      ],
      isShowConsult: false
    };
  },
  computed: {
    filterHouse() {
      return this.houseList.filter(item => item.choose)[0].children;
    },
    ...mapState(["companyConfig"]),
    webSiteInfo() {
      return this.companyConfig.webSiteInfo
    }
  },
  methods: {
    mouseover(house) {
      for (const value of this.houseList) {
        if (house.title === value.title) {
          value.choose = true;
        } else {
          value.choose = false;
        }
      }
    }
  },

};
</script>


<style lang="less" scoped>
.footer-wrapper {
  padding: 40px 0 10px;
  border-top: 1px solid #e4e6f0;
  .address {
    line-height: 20px;
    margin-top: 10px;
    a {
      color: #b7bbc3;
    }
  }
  .children-house-wrapper {
    li {
      margin: 10px 10px 0 0;
    }
  }
  .house-wrapper {
    margin-top: 24px;
    li {
      margin-right: 57px;
    }
    .no-choose {
      color: #707787;
    }
  }
  .nav-item {
    padding: 0 25px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    margin-right: 42px;
  }
  .modal-inner {
    width: 360px;
    padding: 0 40px 30px;
    .close-modal {
      position: absolute;
      right: 15px;
      top: 15px;
    }
    .title-top {
      line-height: 22px;
    }
    .req-btn-span {
      margin-left: 8px;
      display: inline-block;
      width: 64px;
      height: 22px;
      border: 1px solid #00ae66;
      border-radius: 2px;
      cursor: pointer;
      font-weight: 700;
      background-size: 100%;
      text-align: center;
      line-height: 22px;
      font-size: 12px;
      color: #00ae66;
    }
  }
}
</style>
