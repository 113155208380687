<template>
  <div>
    <div v-if="currentPage === '/'" class="home-banner white sb_center row">
      <div class="header-left row ">
        <img :src="whiteLogo" alt="" />
        <div class="company-wrapper ">
          <span>{{companyConfig.firstName}}</span>
          <p>买房大平台</p>
        </div>
        <div  @click="isShowCitySelect = true" class="loctaion-wrapper row c_center cursor">
          <i class="el-icon-location"></i>
          <span class="size12">{{location.cityName}}</span>
        </div>
      </div>
      <div class="header-right row size16 bold">
        <ul class="row router-wrapper">
          <li v-for="nav in navs" class="cursor" :key="nav.name">
            <router-link v-if="isSourceERP ? nav.name !== '小区':true" class="white" :to="nav.page">
              {{nav.name}}
            </router-link>
          </li>
          <li v-if="!isSourceERP" class="cursor  download">
            <span>下载App</span>
            <div class="download-app-qr-code transition column  c_center ">
              <img class="icon-green" :src="greenLogo" alt="" />
              <p class="title size18 bold">{{companyConfig.firstName + companyConfig.lastName}}</p>
              <p class="sub-title size12">让家更美好</p>
              <img class="qr-code" :src="downloadUrl" alt="" />
            </div>
          </li>
        </ul>
        <div v-if="!isSourceERP" class="login-wrapper row s_center ">
          <img src="@/assets/imgs/app_icon_user_login.png" alt="" />
          <div v-if="user">
            <router-link to="/setting">{{user.customer.phone | replaceMobile}}</router-link>
            <a @click='loginOut' href="javascript:;">退出</a>
          </div>
          <div @click="updateShowLogin" v-else>
            <a href="javascript:;">登录</a>
            <span>/</span>
            <a href="javascript:;"> 注册</a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="other-banner" :class="[currentPage === '/setting'?'greenBG':'banner-bg']">
      <div class="row sb_center content" style="height: 100%;">
        <ul class="row router-wrapper">
          <li v-if="!isSourceERP && currentPage !== '/'" class="cursor">
            <router-link style="height:38px" class="colb3 flex c_center" :class="[currentPage === '/setting' ?'white' :'']" to="/">
              <div class="flex s_center" v-if="currentPage === '/setting'">
                <img height="28" :src="companyConfig.miniLogo" alt="" />
                <span class="white bold size24 pl10">{{companyConfig.firstName}}</span>
              </div>
              <span v-else>首页</span>
            </router-link>
          </li>
          <li v-for="nav in navs" class="cursor" :key="nav.name">
            <router-link v-if="isSourceERP ? nav.name !== '小区':true" :class="[currentPage === nav.page || currentPage === '/setting' ? 'white':'colb3']" :to="nav.page">
              {{nav.name}}
            </router-link>
          </li>
          <li v-if="!isSourceERP" class="cursor colb3 download" :class="[currentPage === '/setting' ?'white' :'']">
            <span>下载App</span>
            <div class="app-qr-code transition">
              <img :src="downloadUrl" alt="" />
            </div>
          </li>
        </ul>
        <div v-if="user" class="login-wrapper  size14">
          <router-link to="/setting">{{user.customer.phone | replaceMobile}}</router-link>
          <a href="javascript:;" @click='loginOut'>退出</a>
        </div>
        <div v-else-if="!isSourceERP" class="login-wrapper colb3 size14">
          <i class="el-icon-s-custom"></i>
          <a @click="updateShowLogin" href="javascript:;" class="logo-in">登录/注册</a>
        </div>
      </div>
    </div>
    <div v-if="currentPage === '/'" class="home-bg">
    </div>
    <city-select :show-city-select="isShowCitySelect" @update-city-select="isShowCitySelect = $event" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import CitySelect from "@/components/city-select";
import tool from "@/utils/tool.js";
export default {
  data() {
    return {
      navs: [
        { name: "二手房", page: "/house/2" },
        { name: "新房", page: "/loupan" },
        { name: "租房", page: "/house/1" },
        { name: "小区", page: "/house/4" }
      ],
      isShowCitySelect: false
    };
  },
  components: {
    CitySelect
  },
  filters: {
    replaceMobile(mobile) {
      return tool.mobileTo2_2(mobile);
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
    ...mapState(["location", "user","companyConfig","isSourceERP"]),
    downloadUrl() {
      return this.companyConfig.downloadUrl
    },
    whiteLogo() {
      return this.companyConfig.whiteLogo
    },
    greenLogo() {
      return this.companyConfig.greenLogo
    }
  },
  methods: {
    updateShowLogin() {
      this.$store.commit("updateState", {
        data: true,
        key: "showLogin"
      });
    },
    loginOut() {
      this.$store.commit("saveUser", null);
      this.$router.push("/");
    }
  }
};
</script>

<style lang="less" scoped>
.other-banner {
  height: 38px;
  line-height: 38px;

  .download:hover {
    .app-qr-code {
      display: block;
    }
  }
}

.home-banner {
  padding: 34px 50px 0;
  .download:hover {
    .download-app-qr-code {
      transform: scale(1);
    }
  }
}
.header-left img {
  width: 42px;
  height: 42px;
}
.home-bg {
  width: 100%;
  height: 566px;
  background: url("../../../assets/imgs/home_header_bg.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.company-wrapper {
  margin-left: 8px;
  font-size: 22px;
}
.company-wrapper p {
  font-size: 10px;
  letter-spacing: 4px;
  transform: scale(0.6) translateX(-26px);
}
.loctaion-wrapper {
  padding: 0 10px;
  height: 36px;
  border-radius: 36px;
  border: 2px solid #fff;
  box-sizing: border-box;
}
.router-wrapper {
  li {
    height: 38px;
    line-height: 38px;
    position: relative;
    &:not(:last-child) {
      margin-right: 63px;
    }
    &:hover {
      a {
        color: #fff;
      }
    }
    .app-qr-code {
      position: absolute;
      top: 38px;
      width: 114px;
      height: 114px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      background-color: #101d37;
      box-shadow: 0 0 8px #101d37;
      padding: 12px;
      z-index: 10;
      box-sizing: border-box;
      display: none;
      img {
        width: 90px;
        height: 90px;
      }
    }
    .download-app-qr-code {
      position: absolute;
      top: 38px;
      margin-left: -60px;
      background-color: #fff;
      border-radius: 4px;
      padding: 52px 0 32px;
      width: 200px;
      color: #101d37;
      z-index: 2;
      transform-origin: 50% 0;
      transform: scale(0);
      .icon-green {
        width: 72px;
        height: 72px;
        margin-bottom: 12px;
      }
      .title {
        line-height: 25px;
      }
      .sub-title {
        line-height: 17px;
        font-size: 12px;
        margin-bottom: 30px;
      }
      .qr-code {
        width: 120px;
        height: 120px;
      }
    }
  }
}
.login-wrapper {
  height: 38px;
  margin: 0 50px 0 55px;
}
.login-wrapper {
  img {
    width: 26px;
    height: 26px;
  }
  a {
    display: inline-block;
    padding: 0 10px;
    color: #fff;
  }
  .logo-in {
    color: #b3b3b3;
  }
  .logo-in:hover {
    color: #ffffff;
  }
}
</style>


