<template>
  <div v-show="showLogin" class="modal">
    <div class="modal-inner">
      <div class="close-btn cursor" @click="updateShowLogin(false)">
        <i class="el-icon-close size18 black"></i>
      </div>
      <h1 class="size20 black mb10 bold">{{showRetrievePassword ? '找回密码' : isMobileLogin ?"手机快捷登录":"账号密码登录"}}</h1>
      <span v-if="isMobileLogin" class="size14 colb3">别担心，无账号自动注册不会导致手机号被泄露</span>
      <div v-if="showRetrievePassword" class="retrieve-password">
        <el-input clearable maxlength="11" placeholder="请输入手机号" v-model="form.mobile" class="moble-input" @input="onInput($event,'mobile')"></el-input>
        <div class="flex sb_center">
          <el-input clearable maxlength="6" @input="onInput($event,'vaildCode')" placeholder="请输入短信验证码" v-model="form.vaildCode"></el-input>
          <div class="get-code-box">
            <el-button plain @click="onRetrievePasswordSendSms" size="mini" :loading="isLoadingCode" :disabled="isDisabled">{{codeTitle}}</el-button>
          </div>
        </div>
        <el-input class="password-input" clearable maxlength="20" placeholder="请输入密码(最少6位,数字+字母)" v-model="form.password" show-password />
      </div>
      <div v-else-if='isMobileLogin' class="login-input">
        <el-input clearable maxlength="11" placeholder="请输入手机号" v-model="form.mobile" class="moble-input" @input="onInput($event,'mobile')"></el-input>
        <div class="flex sb_center">
          <el-input clearable maxlength="6" @input="onInput($event,'vaildCode')" placeholder="请输入短信验证码" v-model="form.vaildCode"></el-input>
          <div class="get-code-box">
            <el-button plain @click="onSendSms" size="mini" :loading="isLoadingCode" :disabled="isDisabled">{{codeTitle}}</el-button>
          </div>
        </div>
      </div>
      <div v-else style="margin: 36px 0 40px;">
        <el-form ref="form" :model="form">
          <el-form-item label="">
            <el-input clearable maxlength="11" placeholder="请输入手机号" @input="onInput($event,'mobile')" v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
          </el-form-item>
        </el-form>
      </div>
      <a v-if="!isMobileLogin && !showRetrievePassword" href="javascript:;" class="green inline-b" style="margin-bottom:20px" @click="updateRetrievePassword">忘记密码</a>
      <div class="flex">
        <el-button type="primary" style="border:none;background-color: #00AE66;" class=" flex1" @keyup.enter.native="submitForm" @click.prevent="submitForm" :loading="isLoadingLogin">{{loginTitle}}</el-button>
      </div>
      <div class="mt20">
        <a href="javascript:;" @click="updateMobileLogin" class="size14 green">{{showRetrievePassword || isMobileLogin?"账号密码登录":"手机快捷登录"}}</a>
      </div>
      <div v-if="!showRetrievePassword" class="size12 colb3 mt10 argee-wrap">
        登录及代表同意<router-link class="green" to="/zhuanti/2" target="_blank">《隐私政策》</router-link>及
        <router-link class="green" to="/zhuanti/1" target="_blank">《用户服务协议》</router-link>
      </div>
    </div>

  </div>
</template>
<script>
import userServer from "../services/userServer";
import tool from "../utils/tool.js";
import { mapState } from "vuex";
import { setTimeout } from "timers";
export default {
  name: "Login",
  props: {
    onLoad: {
      type: Function,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoadingCode: false,
      isLoadingLogin: false,
      isDisabled: false,
      isMobileLogin: true,
      isLoading: false /*  解決多次错误提示 */,
      showRetrievePassword: false, //是否显示找回密码
      form: {
        mobile: "",
        vaildCode: "",
        password: ""
      },
      loginTitle: "登录",
      codeTitle: "获取验证码",
      count: 60,
      timer: null,
      loginBtnRef: null
    };
  },
  computed: {
    ...mapState(["showLogin"])
  },

  methods: {
    onInput(value, key) {
      this.form[key] = tool.isNumber(value);
    },
    updateShowLogin(isShow) {
      this.$store.commit("updateState", {
        data: isShow,
        key: "showLogin"
      });
    },
    checkValidate(value) {
      var reg = /^[1-9]\d*|0$/; // 定义正则表达式
      var flag = reg.test(value); // 规则校验
      if (flag) {
        return true;
      } else {
        return false;
      }
    },
    checkMobile(phone) {
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(phone)) {
        return true;
      } else {
        return false;
      }
    },
    async onMobileLogin() {
      const { mobile, vaildCode } = this.form;
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      if (!vaildCode) {
        this.toast({ message: "请输入验证码" });
        return;
      }
      if (
        this.checkMobile(mobile) &&
        this.checkValidate(vaildCode) &&
        vaildCode.length === 4
      ) {
        this.onSubmit(1);
      } else {
        this.isLoadingLogin = false;
        this.toast({ message: "格式错误" });
      }
    },
    onPassWordLogin() {
      const { mobile, password } = this.form;
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      if (!password) {
        this.toast({ message: "请输入密码" });
        return;
      }
      if (!this.checkMobile(mobile)) {
        this.toast({ message: "手机号格式错误" });
        return;
      }
      if (password.length < 6) {
        this.toast({ message: "密码长度不能小于6位" });
        return;
      }
      this.onSubmit(2);
    },
    submitForm() {
      if (this.isLoading) return;
      this.isLoading = true;

      if (this.showRetrievePassword) {
        this.updatePassword();
        return;
      }

      if (this.isMobileLogin) {
        this.onMobileLogin();
      } else {
        this.onPassWordLogin();
      }
    },
    updateState({ data, key }) {
      this.$store.commit("updateState", {
        data,
        key
      });
    },
    async onSubmit(type) {
      if (this.isLoadingLogin) return;
      this.isLoadingLogin = true;
      this.loginTitle = "登录中...";
      if (type === 1) {
        this.form.password = "";
      } else {
        this.form.vaildCode = "";
      }
      try {
        const { data } = await userServer.userLogin(this.form);
        this.updateState({ data: false, key: "showLogin" });
        if (data) {
          this.$store.commit("saveUser", data[0]);
        }
      } catch (err) {
        this.toast({ message: err.message });
      } finally {
        this.loginTitle = "登录";
        this.isLoadingLogin = false;
        this.codeTitle = "获取验证码";
        this.count = 60;
        this.timer = null;
        this.form = {
          ...this.form,
          vaildCode: "",
          password: ""
        };
        this.isLoading = false;
      }
    },
    onSendSms() {
      const { mobile } = this.form;
      this.clearTimer();
      if (!mobile) {
        this.toast({
          message: "请填写手机号",
          type: "error"
        });
        return;
      }
      let flag = this.checkMobile(mobile);
      if (!flag) {
        this.toast({
          message: "手机号格式错误",
          type: "error"
        });
        return;
      }
      if (this.isDisabled) return;
      this.isLoadingCode = true;
      this.isDisabled = true;
      userServer
        .sendLoginCode({ value: mobile })
        .then(() => {
          this.countDown();
          this.$message({
            type: "success",
            message: "发送成功"
          });
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "发送失败"
          });
          this.isDisabled = false;
        })
        .finally(() => {
          this.isLoadingCode = false;
        });
    },
    onRetrievePasswordSendSms() {
      if (this.isLoading) return;
      this.isLoading = true;
      const { mobile } = this.form;
      this.clearTimer();
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      let flag = this.checkMobile(mobile);
      if (!flag) {
        this.toast({ message: "手机号格式错误" });
        return;
      }
      if (this.isDisabled) return;
      this.isLoadingCode = true;
      this.isDisabled = true;
      userServer
        .sendResetPasswordCode({ value: mobile })
        .then(() => {
          this.countDown();
          this.toast({ message: "发送成功", type: "success" });
        })
        .catch(() => {
          this.toast({ message: "发送失败" });
          this.isDisabled = false;
        })
        .finally(() => {
          this.isLoadingCode = false;
          this.isLoading = false;
        });
    },
    countDown() {
      this.timer = setTimeout(() => {
        this.count--;
        this.codeTitle = "获取验证码(" + this.count + ")";
        if (this.count == 0) {
          this.codeTitle = "获取验证码";
          this.isDisabled = false;
          this.count = 60;
        } else {
          this.countDown();
        }
      }, 1000);
    },
    clearTimer() {
      this.timer && clearTimeout(this.timer);
    },
    toast({ message, type = "error", duration = 1500, onClose }) {
      this.$message({
        message,
        type,
        duration,
        onClose: () => {
          this.isLoading = false;
          onClose && onClose();
        }
      });
    },
    updateMobileLogin() {
      if (this.showRetrievePassword) {
        this.showRetrievePassword = false;
      } else {
        this.isMobileLogin = !this.isMobileLogin;
        this.loginTitle = "登录";
      }
    },
    async updatePassword() {
      const { mobile, vaildCode, password } = this.form;
      if (!mobile) {
        this.toast({ message: "请填写手机号" });
        return;
      }
      if (!vaildCode) {
        this.toast({ message: "请输入验证码" });
        return;
      }
      if (!password) {
        this.toast({ message: "请输入密码" });
        return;
      }
      if (!this.checkMobile(mobile)) {
        this.toast({ message: "手机号格式错误" });
        return;
      }
      if (vaildCode.length !== 4) {
        this.toast({ message: "验证码格式错误" });
        return;
      }
      if (password.length < 6) {
        this.toast({ message: "密码格式不正确" });
        return;
      }
      this.isLoadingLogin = true;
      this.loginTitle = "修改中···";
      try {
        const { data } = await userServer.resetPassword(this.form);
        this.toast({
          message: "修改成功!",
          type: "success",
          onClose: () => {
            this.isMobileLogin = false;
            this.showRetrievePassword = false;
            this.isLoadingLogin = false;
          }
        });
      } catch (err) {
        this.toast({
          message: err.message,
          onClose: () => {
            this.isLoadingLogin = false;
          }
        });
      } finally {
        this.loginTitle = "登录";
        this.codeTitle = "获取验证码";
        this.count = 60;
        this.timer = null;
        this.form = {
          ...this.form,
          vaildCode: "",
          password: ""
        };
        this.isLoading = false;
      }
    },
    updateRetrievePassword() {
      this.showRetrievePassword = true;
      this.loginTitle = "修改密码";
    }
  },

  beforeDestroy() {
    this.clearTimer();
  }
};
</script>

<style scoped>
.argee-wrap a {
  text-decoration: underline;
}
.modal {
  z-index: 9999;
}
.modal-inner {
  width: 380px;
  padding: 44px 40px;
  box-sizing: border-box;
}
.login-input,
.retrieve-password {
  margin: 36px 0 40px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.moble-input {
  border-bottom: 1px solid #e6e6e6;
}
.login-input >>> .el-input__inner,
.retrieve-password >>> .el-input__inner {
  border: none;
}
.password-input {
  border-top: 1px solid #dcdfe6;
}
.get-code-box >>> .el-button {
  border: none;
}
.get-code-box {
  border-left: 2px solid #ccc;
  border-radius: 2px;
}
.close-btn {
  position: absolute;
  right: 22px;
  top: 22px;
}
.el-button--primary:hover {
  background-color: #00ae66;
}
</style>